<template>
  <div class="about">
    <h1 class="title">Batch Jobs</h1>
    <div class="content">
      <p>
        When a batch destination hits its scheduled time, a batch job is created to send the fulfilments.
        The fulfilments are assigned to this batch job, so it knows what to send.
      </p>
    </div>

    <span>Tasks</span>
    <div class="is-loading content">
      <ol type="1">
        <li>Can see status of batch job and settings where its going (maybe to show in details dialog or something expandable)</li>
      </ol>
    </div>
    <br />

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">
            Scheduled Date Range
            <span v-if="filterDateRange && filterDateRange.start">
              (
              <a v-on:click="filterDateRange = null">Clear</a>)
            </span>
          </label>
          <v-date-picker
            mode="range"
            placeholder="click to set"
            v-model="filterDateRange"
            disabled-attribute="disabled"
            :input-props="{
              readonly: true
            }"
          ></v-date-picker>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Job Id</label>
          <div class="control">
            <div style="white-space:nowrap">
              <input
                class="input"
                type="text"
                placeholder="id e.g 199"
                v-model="filteredByBatchJobId"
                v-on:change="updateFilter"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Status</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="filteredByStatus" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option value="0">Unprocessed Unassigned</option>
                <option value="1">File Created Unsent</option>
                <option value="2">Sent</option>
                <option value="3">Nothing To Send</option>
                <option value="4">Failed</option>
                <option value="5">Manual Resend</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column" v-if="destinations">
        <div class="field">
          <label class="label">Destination</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="filteredByDestinationId" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option v-for="d in destinations" :value="d.Id" :key="d.Id">{{ d.Name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Page size</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="size" v-on:change="updateFilter">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p>
      <b-pagination
        v-if="batchJobs"
        size="is-small"
        :simple="false"
        :total="batchJobs.TotalRows"
        :current.sync="currentPaginationPage"
        :per-page="batchJobs.Limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="paginatePage"
      ></b-pagination>

      <br />
    </p>

    <div v-if="isLoading">
      <a class="button is-large is-loading">Loading</a>
      <br />
      <br />
    </div>
    <table
      v-else-if="batchJobs !== null && batchJobs.Results.length > 0"
      class="table is-size-6 is-fullwidth"
    >
      <tr>
        <th>Id</th>
        <th>Destination</th>
        <th></th>
        <th class="has-text-centered">Status</th>
        <th class="has-text-centered">File</th>
        <th class="has-text-centered">Sales</th>
        <th>Actions</th>
      </tr>
      <tr v-for="(r, index) in batchJobs.Results" :key="index">
        <td>
          <vloader v-if="inProgressBatchJobs.indexOf(r.Id) >= 0" title="action in progress"></vloader>
          <span v-else>{{ r.Id }}</span>
        </td>
        <td>
          ID:{{ r.BatchDestinationId }}
          <br />
          <router-link
            :to="'/batch-destination?nameContains=' + r.BatchDestinationName"
          >{{ r.BatchDestinationName }}</router-link>
        </td>
        <td>
          <div class="flex-table">
            <div class="flex-row">
              <b>Scheduled:</b>&nbsp;
              <span>{{ r.ScheduledRuntime }}</span>
            </div>
            <div class="flex-row">
              <b>Run At:</b>&nbsp;
              <span>{{ r.RunAt || "N/A" }}</span>
            </div>
          </div>
        </td>
        <td class="has-text-centered has-text-vertical-middle">
          <span v-if="r.Status == 2" class="tag is-success is-light">{{ r.StatusAsString }}</span>
          <span v-else-if="r.Status == 3" class="tag is-warning is-light">Nothing to send</span>
          <span v-else class="tag is-danger is-light">{{ r.StatusAsString }}</span>
        </td>
        <td class="has-text-centered has-text-vertical-middle">
          <a v-if="r.AccessDownloadFilePath" :href="r.AccessDownloadFilePath" target="_blank">
            <i class="fas fa-file-download" style="font-size: 24px"></i>
          </a>
        </td>
        <td class="has-text-centered has-text-vertical-middle">
          <a href v-on:click.prevent="batchJobFulfilmentId = r.Id">{{ r.FulfilmentCount}}</a>
        </td>
        <td class="has-text-vertical-middle">
          <b-dropdown aria-role="list" class="is-right">
            <button class="button is-primary is-small" slot="trigger">
              <span>Options</span>
              &nbsp;
              <i class="fas fa-caret-down"></i>
            </button>

            <b-dropdown-item aria-role="listitem" v-on:click="loadBatchJob(r.Id)">View Logs</b-dropdown-item>

            <b-dropdown-item
              class="is-danger"
              aria-role="listitem"
              v-on:click="resendBatchJobConfirm(r)"
            >
              <i class="fas fa-exclamation"></i>&nbsp;Resend job
            </b-dropdown-item>

            <b-dropdown-item
              class="is-danger"
              aria-role="listitem"
              v-on:click="recreateAndResendBatchJobConfirm(r)"
            >
              <i class="fas fa-exclamation"></i>&nbsp;Recreate and resend job
            </b-dropdown-item>

            <b-dropdown-item
              class="is-danger"
              aria-role="listitem"
              v-on:click="$router.push('/fulfilment?batchJobId=' + r.Id)"
            >Fulfilments</b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
    </table>
    <div v-else>No Results found</div>

    <b-pagination
      v-if="batchJobs"
      size="is-small"
      :simple="false"
      :total="batchJobs.TotalRows"
      :current.sync="currentPaginationPage"
      :per-page="batchJobs.Limit"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="paginatePage"
    ></b-pagination>

    <batch-job-log-dialog
      v-if="batchJobDetail !== null"
      :batch-job="batchJobDetail"
      v-on:close="batchJobDetail = null"
    ></batch-job-log-dialog>

    <batch-job-fulfilment-table
      v-if="batchJobFulfilmentId"
      :batch-job-id="batchJobFulfilmentId"
      v-on:close="batchJobFulfilmentId = null"
    ></batch-job-fulfilment-table>
  </div>
</template>
<script>
import { baseMixin } from "../../mixins/baseMixin.js";
export default {
  components: {
    batchJobLogDialog: () =>
      import("../components/organisms/batchJobLogDialog.vue"),
    batchJobFulfilmentTable: () =>
      import("../components/organisms/batchJobFulfilmentTable.vue"),
    vloader: () => import("../components/atom/vloader")
  },
  mixins: [baseMixin],
  data: function() {
    // var today = new Date();
    // var priorDate = new Date(new Date().setDate(new Date().getDate() - 30));

    return {
      APIUrl: process.env.VUE_APP_APIURL,
      batchJobs: null,
      inProgressBatchJobs: [],
      batchJobDetail: null,
      batchJobFulfilmentId: null,
      isLoading: false,
      currentPaginationPage: 1,
      size: 10,
      filteredByDestinationId: null,
      filteredByBatchJobId: null,
      filteredByStatus: null,
      filterDateRange: {
        start: null,
        end: null
      },
      destinations: null
    };
  },
  watch: {
    // whenever question changes, this function will run
    filterDateRange: {
      deep: true,
      handler: function(newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateFilter();
        }
      }
    }
  },
  created: function() {
    // console.log(this.$route.query);

    this.size = this.$route.query.size || 10;

    this.currentPaginationPage = this.$route.query.pageNumber
      ? parseInt(this.$route.query.pageNumber)
      : 1;

    this.filteredByDestinationId = this.$route.query.destinationId
      ? parseInt(this.$route.query.destinationId)
      : null;

    this.filteredByBatchJobId = this.$route.query.batchJobId
      ? parseInt(this.$route.query.batchJobId)
      : null;

    this.filteredByStatus = this.$route.query.status
      ? parseInt(this.$route.query.status)
      : null;

    if (this.$route.query.dateFrom)
      this.filterDateRange.start = new Date(this.$route.query.dateFrom);
    if (this.$route.query.dateTo)
      this.filterDateRange.end = new Date(this.$route.query.dateTo);

    this.loadDestinationOptions();
    this.updateUrlAndLoadPage(true);
  },
  methods: {
    updateFilter() {
      this.currentPaginationPage = 1;
      this.updateUrlAndLoadPage();
    },
    updateUrlAndLoadPage(replace) {
      var url = "batch-jobs?size=" + this.size;

      if (this.currentPaginationPage)
        url += "&pageNumber=" + this.currentPaginationPage;

      if (this.filteredByDestinationId)
        url += "&destinationId=" + this.filteredByDestinationId;

      if (this.filteredByBatchJobId)
        url += "&batchJobId=" + this.filteredByBatchJobId;

      if (this.filteredByStatus) url += "&status=" + this.filteredByStatus;

      if (this.filterDateRange && this.filterDateRange.start)
        url +=
          "&dateFrom=" + this.getDateAsISOFormat(this.filterDateRange.start);
      if (this.filterDateRange && this.filterDateRange.end)
        url += "&dateTo=" + this.getDateAsISOFormat(this.filterDateRange.end);

      if (replace) this.$router.replace(url);
      else this.$router.push(url);

      this.loadData();
    },
    loadData() {
      this.isLoading = true;
      this.axios.get(this.apiEndpointUrl).then(response => {
        this.batchJobs = response.data;
        this.isLoading = false;
      });
    },
    loadDestinationOptions() {
      var url = this.APIUrl + "/destination/all/basic";
      this.axios.get(url).then(response => {
        this.destinations = response.data;
      });
    },
    apiUrlGetBatchJobById(id) {
      return (
        this.APIUrl +
        "/batch-jobs/" +
        id +
        "?includeLogs=true&includeFulfilment=false"
      );
    },
    paginatePage(page) {
      var pageInt = parseInt(page);
      if (pageInt <= 0) pageInt = 1;
      this.currentPaginationPage = pageInt;
      this.updateUrlAndLoadPage();
    },
    loadBatchJob(batchJobId) {
      var url = this.apiUrlGetBatchJobById(batchJobId);

      this.axios.get(url).then(response => {
        this.batchJobDetail = response.data;
      });
    },
    recreateAndResendBatchJobConfirm(d) {
      //resend stuff

      var title = "Recreate files and resend batch job";
      var msg =
        "Are you sure you wish to recreate file and resend '<b>" +
        d.BatchDestinationName +
        "</b>' batch job for the <b>" +
        d.FulfilmentCount +
        " orders</b> that was scheduled to run at <b>" +
        d.ScheduledRuntime +
        "</b>?";

      this.$dialog.confirm({
        title: title,
        message: msg,
        type: "is-danger",
        onConfirm: () => this.recreateAndResendBatchJob(d)
      });
    },
    recreateAndResendBatchJob(d) {
      var url = this.APIUrl + "/batch-jobs/" + d.Id + "/recreate-and-resend";

      this.axios.put(url).then(response => {
        console.log(response.data);

        this.$toast.open(
          "Batch job has been triggered to recreate and resend, should be completed in the next couple minutes"
        );

        this.inProgressBatchJobs.push(d.Id);

        this.checkJobIsResent(d);
      });
    },
    resendBatchJobConfirm(d) {
      //resend stuff

      var title = "Resend batch job";
      var msg =
        "Are you sure you wish to resend '<b>" +
        d.BatchDestinationName +
        "</b>' batch job for the <b>" +
        d.FulfilmentCount +
        " orders</b> that was scheduled to run at <b>" +
        d.ScheduledRuntime +
        "</b>?";

      this.$dialog.confirm({
        title: title,
        message: msg,
        type: "is-danger",
        onConfirm: () => this.resendBatchJob(d)
      });
    },
    resendBatchJob(d) {
      var url = this.APIUrl + "/batch-jobs/" + d.Id + "/resend";

      this.axios.put(url).then(response => {
        console.log(response.data);

        this.$toast.open(
          "Batch job has been triggered to resend, should be completed in the next couple minutes"
        );

        this.inProgressBatchJobs.push(d.Id);

        this.checkJobIsResent(d);
      });
    },
    checkJobIsResent(d) {
      var url = this.apiUrlGetBatchJobById(d.Id);

      var bjobs = this.batchJobs.Results;

      //var VueSet = this.Vue;
      this.axios.get(url).then(response => {
        var bj = response.data;
        console.log(bjobs);
        console.log(this);
        console.log(this.$set);
        this.$set(bjobs, bjobs.findIndex(f => f.Id === bj.Id), bj);

        if (response.data.Status == 2) {
          // all done, ready to go!
          this.$toast.open("Job has resent: " + d.Id);
          this.removeFromArrayObject(this.inProgressBatchJobs, bj.Id);
        } else {
          var that = this;
          setTimeout(function() {
            that.checkJobIsResent(bj);
          }, 2000);
        }
      });
    },
    removeFromArrayObject(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
  },
  computed: {
    apiEndpointUrl: function() {
      var url =
        this.APIUrl +
        "/batch-jobs?take=" +
        this.size +
        "&pageNumber=" +
        (this.currentPaginationPage - 1);

      if (this.filteredByDestinationId !== null)
        url += "&batchDestinationId=" + this.filteredByDestinationId;

      if (this.filteredByBatchJobId !== null)
        url += "&batchJobId=" + this.filteredByBatchJobId;

      if (this.filteredByStatus !== null)
        url += "&status=" + this.filteredByStatus;

      if (this.filterDateRange && this.filterDateRange.start)
        url +=
          "&dateFrom=" + this.getDateAsISOFormat(this.filterDateRange.start);
      if (this.filterDateRange && this.filterDateRange.end)
        url += "&dateTo=" + this.getDateAsISOFormat(this.filterDateRange.end);

      return url;
    }
  }
};
</script>
<style>
.flex-table {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row > b {
  flex: 0 0 100px;
}
</style>
